var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-table", {
    staticClass: "gx-table-responsive",
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.teams,
      loading: _vm.teamsLoading,
    },
    scopedSlots: _vm._u([
      {
        key: "operations",
        fn: function (text, row) {
          return _c(
            "div",
            { staticClass: "gx-text-right" },
            [
              row.status === "active"
                ? _c(
                    "router-link",
                    {
                      attrs: { to: "/teams/" + row.id },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.ManageTeam.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: {
                            "margin-left": "5px",
                            "margin-bottom": "0",
                          },
                          attrs: { type: "primary", size: "small" },
                        },
                        [_vm._v("\n        Manage Team\n      ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              row.status === "active"
                ? _c(
                    "a-button",
                    {
                      staticStyle: {
                        "margin-left": "5px",
                        "margin-bottom": "0",
                      },
                      attrs: { type: "danger", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.archiveTeam(row.id)
                        },
                      },
                    },
                    [_vm._v("\n      Archive\n    ")]
                  )
                : _vm._e(),
              _c(
                "a-button",
                {
                  staticStyle: {
                    color: "#f5222d",
                    "background-color": "#f5f5f5",
                    "border-color": "#d9d9d9",
                    "margin-right": "5px",
                    "margin-bottom": "0px",
                  },
                  attrs: { size: "small", type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteTeam(row.id)
                    },
                  },
                },
                [_vm._v("Delete")]
              ),
              row.status === "archived"
                ? _c(
                    "a-button",
                    {
                      staticStyle: {
                        "margin-left": "5px",
                        "margin-bottom": "0",
                      },
                      attrs: { type: "danger", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.activateTeam(row.id)
                        },
                      },
                    },
                    [_vm._v("\n      Make Active\n    ")]
                  )
                : _vm._e(),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }